import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css' // 引用bootstrap的样式
import 'bootstrap/dist/js/bootstrap.min.js' // 引用bootstrap的js
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.prototype.$isLogin = false
Vue.use(ElementUI)



new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
