<template>
  <div>
    <el-dialog width="100%;" title="Pedido提示" :visible.sync="tagVisible" :fullscreen="true"
               :close-on-click-modal="false"
               @close="channel">
      <el-form ref="formData" :model="form" :rules="rules" label-position="right">
        <el-form-item prop="name" size="medium">
          <el-input v-model="form.name" autocomplete="off" placeholder="Cliente/Nombre店名和姓名" @blur="toBlur(1)"
                    @input="toUpper(1)">
            <template slot="prepend">
              <i class="el-icon-user-solid" style="font-size: 18px;"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="rif" size="medium">
          <el-input v-model="form.rif" autocomplete="off" placeholder="RIF税号" @input="toUpper(2)" @blur="toBlur(2)">
            <template slot="prepend">
              <i class="el-icon-s-check" style="font-size: 18px;"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="phone" size="medium">
          <el-input v-model="form.phone" autocomplete="off" placeholder="Teléfono请输入联系电话" @input="toUpper(4)" @blur="toBlur(3)">
            <template slot="prepend">
              <i class="el-icon-phone" style="font-size: 18px;"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="consumer_email" size="medium">
          <el-input v-model="form.consumer_email" autocomplete="off" placeholder="Email邮箱地址" @blur="toBlur(4)">
            <template slot="prepend">
              <img alt="" src="@/assets/mail.png" style="width: 16px;">
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="address" size="medium">
          <div>Direccion地址：</div>
          <el-input v-model="form.address" :rows="4" autocomplete="off" placeholder="Direccion地址" type="textarea"
                    @input="toUpper(3)">
            <template slot="prepend">
              <i class="el-icon-location" style="font-size: 18px;"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item size="medium"  prop="desc">
          <div>Concepto备注：</div>
          <el-input v-model="form.desc" :rows="4"
                    autocomplete="off" placeholder="Concepto需要备注的，您可以写在这里" type="textarea" @blur="toBlur(5)">
          </el-input>
        </el-form-item>
      </el-form>

      <div class="text-center">
        <div style="color: #666;">Confirmar la entrega del pedido?</div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button class="btn-channel" size="medium" @click="channel()">Cancelar取消</el-button>
        <el-button :icon="sendStatus ? 'el-icon-loading': ''" :disabled="sendStatus" class="btn-primary" type="primary"
                   size="medium" @click="save()">{{ sendStatus ? '订单提交中' : 'Entregar 提交订单' }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {saveOrder} from "@/api";

// const checkEmail = (rule, value, callback) => {
//   const mailReg = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
//   if (value) {
//     setTimeout(() => {
//       if (mailReg.test(value)) {
//         callback()
//       } else {
//         callback(new Error('请输入正确的邮箱格式'))
//       }
//     }, 100)
//   } else {
//     // callback(new Error('Email邮箱不能为空'))
//   }
// };

export default {
  name: 'OrderC',
  props: {
    dialogShow: {
      type: Boolean,
      default: true
    },
    checkAll: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      formLabelWidth: '100px',
      form: {
        name: '',
        address: '',
        phone: '',
        consumer_email: '',
        desc: '',
        detail: []
      },
      rules: {
        name: [{required: true, message: 'Cliente/Nombre店名和姓名不能为空', trigger: 'blur'}],
        // rif: [{required: true, message: 'RIF税号不能为空', trigger: 'blur'}],
        // address: [{required: true, message: 'Direccion地址不能为空', trigger: 'blur'}],
        // phone: [{required: true, message: 'Teléfono联系电话不能为空', trigger: 'blur'}],
        consumer_email: [{required: true, message: 'Email邮箱不能为空', trigger: 'blur'},],
        // desc: [{required: true, message: 'Concepto备注不能空', trigger: 'blur'}],
      },
      sendStatus: false
    }
  },
  computed: {
    tagVisible: {
      get() {
        return this.dialogShow
      },
      set(v) {
        this.$emit('dialogEvent', v)
      }
    },
  },
  methods: {
    toBlur(type){
        if (!this.form.name && type===1){
          this.$message.error('Cliente/Nombre店名和姓名不能为空')
          return
        }
      // if (!this.form.rif && type===2){
      //   this.$message.error('RIF税号不能为空')
      //   return
      // }
      // if (!this.form.phone && type===3){
      //   this.$message.error('Teléfono联系电话不能为空')
      //   return
      // }
      if (!this.form.consumer_email && type===4){
        this.$message.error('Email邮箱不能为空')
        return
      }
      // if (!this.form.desc && type===5){
      //   this.$message.error('Concepto备注不能空')
      //   return
      // }

    },
    toUpper(type) {
      if (type === 1) {
        this.form.name = this.form.name.toUpperCase();
      }
      if (type===2){
        this.form.rif=this.form.rif.toUpperCase();
      }
      if (type===3){
        this.form.address=this.form.address.toUpperCase();
      }
      if (type===4){
        this.form.phone=this.form.phone.toUpperCase();
      }
    },
    save() {

      this.form.detail = this.rowData
      this.$refs.formData.validate((valid, object) => {
        if (valid) {
          this.sendStatus = true
          const loading = this.$loading({
            lock: true,
            text: '订单提交中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          saveOrder(this.form).then(res => {
            if (res.code === 1) {
              // this.delCart()
              loading.close();
              localStorage.clear()
              this.sendStatus = false
              this.$message.success('下单成功')
              this.toPage()
            } else {
              loading.close();
              this.sendStatus = false
              this.$message.error(res.msg)
            }
          })
          // setTimeout(() => {
          //   loading.close();
          //   this.sendStatus = false
          // }, 6000);

          // console.log('form:',this.form)
        } else {
          let msg = Object.values(object).flat()[0].message
          this.$message.error(msg);
        }
      })
    }
    ,
    channel() {
      this.$refs.formData.resetFields()
      this.$emit('dialogEvent')
      this.$emit('drawerEvent')
    }
    ,
    delCart() {
      // 删除购物车选中的商品

  // localStorage.clear()
  // this.$emit('cartCount', 0)

  let cart = JSON.parse(localStorage.getItem('cart'))
  let newCart = []
  if (this.checkAll) {
    // 是否是全选
    newCart = []
  } else {

    cart.forEach((item) => {
      if (item.checked === false) {
        newCart.push(item)
      }
    })
  }
  this.$emit('cartCount', newCart.length)
  localStorage.setItem('cart', JSON.stringify(newCart))
  console.log(JSON.parse(localStorage.getItem('cart')), '删除后的购物车')
  this.channel()
}
,
toPage()
{
  this.$router.push({
    path: '/success'
  })
}
}
}
</script>

<style lang="scss" scoped>
@import "order";
</style>
